// **********************************************************************

global.testing = false;

// **********************************************************************

global.debugging = global.testing;
global.version = '1.0';
global.items_max = 25; // pagination - max.items na stránke
global.title = 'Aluma';
global.creator = 'Datadream s.r.o.';

// **********************************************************************
// DATABAZA - LINK
// **********************************************************************
global.web = 'https://www.aluma.sk';
global.db_url = global.web + '/app_web/';
global.images = global.web + '/public/images/';
global.product_image = global.web + '/public/images/eshop/';
global.www = 'www.aluma.sk';
global.gallery = global.web + '/public/images/gallery/';
global.mats = global.web + '/public/images/mats/';

// **********************************************************************
// COLORS
// **********************************************************************
global.theme_back = '#F4F5FA';
global.theme_white = '#ffffff';
global.theme_black = '#000000';
global.theme_black_opacity = '#000000bb';
global.theme_light = '#FFC586';
global.theme_medium = '#C7945D';
global.theme_light_blue = '#509DF4';
global.theme_lighter_blue = '#ADCCEF';
global.theme_light_red = '#FFDDDD';
global.theme_light_green = '#C0E0B8';
global.theme_lighter = '#FDE1C2';
global.theme_dark = '#866037';
global.theme_darker = '#624A2F';
global.theme_dark_blue = '#16406F';
global.theme_dark_red = '#F44336';
global.theme_dark_green = '#4D7B4D';
global.theme_blue = '#51AFF5';
global.theme_red = '#F25751';
global.theme_green = '#76C73B';
global.theme_yellow = '#F4B549';
global.theme_badge = '#A12E28';
global.theme_placeholder = '#cccccc';
global.theme_light_violet = '#FFAAFF';
global.theme_none = '#00000000';

global.theme_gray = '#eeeeee';
global.theme_xxgray = '#aaaaaa';
global.theme_dark_gray = '#666666';
global.theme_medium_gray = '#888888';
global.theme_light_gray = '#dedede';
global.theme_menu = '#000000';
global.theme_menu_selected = '#aa0000';
global.theme_menu_background_selected = '#ffeeee';
global.theme_submenu = '#aaaaaa';
global.theme_categorymenu = '#ffffff';

global.theme_selected = '#4D617B';
global.theme_orange = 'orange';
global.theme_violet = 'violet';
global.theme_yellow = 'yellow';
global.theme_brown = 'brown';

global.theme_footer = '#222222';
global.theme_footer_text = '#AAAAAA';
global.theme_footer_text_high = '#CCCCCC';

global.theme_menu = '#00000055';

// **********************************************************************
// DISPLAY SIZE
// **********************************************************************
global.device_small = 600;
global.device_medium = 992;
global.screen_max = 950;

// **********************************************************************
// FONT SIZE
// **********************************************************************
global.font_xxtiny = 12;
global.font_tiny = 14;
global.font_small = 16;
global.font_normal = 18;
global.font_large = 20;
global.font_xlarge = 24;
global.font_xxlarge = 28;
global.font_label = 28;
global.font_dialog_label = 24; // dialogove boxy - label
global.font_menu = 18; // menu - lava strana
global.font_textlabel = 13; // label pre TextField <p></p>
global.font_categorymenu = 14;
global.font_products_grid = 16;
global.font_banner_label = 36;
global.font_banner_text = 18;
global.font_banner_label_small = 18;
global.font_banner_text_small = 14;
global.font_prices = 22;

// **********************************************************************
// RADIUS
// **********************************************************************
global.radius = 5;
global.padding = 40;
global.padding_small = 10;


// **********************************************************************
// COMPANY - UDAJE O SPOLOCNOSTI
// **********************************************************************
global.company = {
    call: '+421 905 666 415',
    name: 'Aluma SK, s.r.o.',
    street: 'Dlhá 64',
    psc: '953 01',
    town: 'Zlaté Moravce',
    state: 'Slovensko',
    mobil1: '+421 905 666 415',
    mobil2: '+421 948 027 078',
    mobil_hu: '+421 915 879 181',
    email: 'info@aluma.sk',
    ico: '45347808',
    dic: '2022976230',
    ic_dph: 'SK2022976230',
}



// **********************************************************************
// SK - LANGUAGE
// **********************************************************************
export const sk = {
    lang_id: 0,
    title: 'Aluma',
    description: 'Výroba, predaj a montáž rohoží a čistiacich zón',
    banner_label: 'Rohože a čistiace zóny',

    home: 'Domov',
    years_business: 'Už 16 rokov na trhu',
    required_data: 'Údaje označené * sú povinné',

    months: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
    months_short: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'Máj.', 'Jún.', 'Júl.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dec.'],
    days: ['Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
    days_short: ['Ned.', 'Pon.', 'Uto.', 'Str.', 'Štv.', 'Pia.', 'Sob.'],
    today: 'Dnes',

    name: 'Meno',
    surname: 'Priezvisko',
    name_surname: 'Meno a priezvisko',
    street: 'Ulica',
    street_number: 'číslo',
    psc: 'PSČ',
    town: 'Mesto',
    email: 'E-mail',
    email_error: 'Nesprávny formát e-mailovej adresy',
    mobil: 'Mobil',
    mobil_sk: 'Mobil (SK)',
    mobil_hu: 'Mobil (HU)',
    phone: 'Tel.číslo',
    state: 'Štát',
    logo: 'Logo',
    ico: 'IČO',
    dic: 'DIČ',
    ic_dph: 'IČ-DPH',
    DPH: 'DPH',
    error: 'Chyba',

    continue: 'Pokračovať',
    back: 'Späť',
    close: 'Zavrieť',

    required: 'Povinný údaj',
    number: 'Číslo',
    active: 'Aktívny',
    label: 'Názov',
    db_error: 'Pri zápise nastala chyba. Skúste požiadavku opakovať',

    new: 'Nový',
    add: 'Pridať',
    ok: 'OK',
    cancel: 'Zrušiť',
    save: 'Uložiť',
    save_changes: 'Uložiť zmeny',
    edit: 'Upraviť',
    search: 'Hľadať',
    send: 'Poslať',
    saving: '...',
    subscribe: 'Odoberať',

    call_us: 'Volajte',

    gdpr: 'Ochrana osobných údajov',
    cookies_rules: 'Zásady používania súborov cookies',
    sell_docs: 'Všeobecné obchodné podmienky',

    about_us: 'O nás',
    products: 'Produkty',
    products_text: 'Výroba a predaj rohoží',
    gallery: 'Galéria',
    gallery_text: 'Galéria fotografií',
    reference: 'Referencie',
    reference_text: 'Naši klienti',
    contact: 'Kontakt',
    contact_text: 'Kontaktujte nás',
    contact_data: 'Kontaktné údaje',
    more_info: 'Viac informácii',

    mat_enter: 'Vstupné rohože',
    mat_enter_text: 'Vyrábame vstupné rohože pre zníženie znečistenia a vlhkosti objektu. Vstupné rohože prispievajú k ochrane hodnoty objektu. Podlahové krytiny budú ochránené pred znečistením a zvyšovaním vlhkosti.',
    our_products: 'Naše produkty',
    mat_catalogue: 'Katalóg rohoží Aluma',
    mat_catalogue_text: 'Rohože Aluma zajistia čistotu Vašej budovy',
    mat_int: 'Interiérové rohože',
    mat_ext: 'Exteriérové rohože',
    mat_ind: 'Priemyselné rohože',
    our_clients: 'Najväčší odberatelia',
    banner_text: 'Rohože sú prvým kontaktom pri vstupe do budovy. Zachytia až 90% nečistôt.',
    message_question: 'Chcete sa na niečo opýtať? Napíšte nám.',
    message_text: 'Text správy',
    contact_form: 'Kontaktný formulár',
    about_us_text1: 'Naša firma je na trhu od roku 2006. Od r.2010 sa zmenila právna forma spoločnosti na ALUMA SK,s.r.o. Zaoberáme sa výrobou a montážou rohoží  pre čistiace zóny.',
    about_us_text2: 'V priebehu rokov prešla firma rôznymi zmenami, rozšírili sme nielen sortiment , ale aj výrobne priestory. Máme dostatočné materiálové zásoby, preto sme rýchli, pohotový a snažíme sa vyhovieť všetkým požiadavkám zákazníka. Veď dnešná doba si to vyžaduje.',
    about_us_text3: 'Od začiatku nášho vzniku ako jediná firma robíme aj servis našich rohoží. Našou výhodu je, že vyrábame podľa požiadaviek a na mieru zákazníka. Spolupracujeme so stavebnými firmami, projektantmi a aj s konečným zákazníkom.',
    about_us_text4: 'Zameriavame sa hlavne na dodávky rohoží pre obchodné centrá, administratívne budovy, banky, hotely a rodinné domy. Naše výrobky nájdete nielen na Slovenskom trhu, ale aj v Čechách a Maďarsku.',

    message_send_od: 'Správa bola úspešne odoslaná',

    mat_data_1: 'Technický popis:',
    mat_data_2: 'Výška:',
    mat_data_3: 'Váha:',
    mat_data_4: 'Hrúbka:',
    mat_data_5: 'Rozmery:',
    mat_data_6: 'Údržba:',
    mat_data_7: 'Použitie:',
    mat_data_8: 'Prevedenie:',

    sub_mat_1: 'Konštrukcia',
    sub_mat_2: 'Násada',
    sub_mat_3: 'Výška rohože',
    sub_mat_4: 'Výška rámu',
    sub_mat_5: 'Medzera',
    sub_mat_6: 'Farba násady',
    sub_mat_7: 'Tlmiaca páska',
    sub_mat_8: 'Rozmer',

    coworking: 'Spolupráca s firmami',
    alumat_ready: 'Stavebná príprava pre osadenie zapustených rohoží Alumat',

    mats: [
        {
            id: 0, enabled: true, type: 0, image: 'alumat_1.jpg', title: 'Alumat', description: 'Exkluzivita a jednoduchosť v jednom',
            text1: 'rohož z hliníkových profilov, spojená antikorovým lankom a gumičkami, ktoré tvoria medzery medzi profilmi a tým umožňujú prepadávanie nečistoty. Táto medzera môže byť 5mm, 8mm a 10mm. Povrch tvorí textil, guma (pilka), alebo protišmyková kocka, ktoré sa môžu striedať s hliníkovou škrabkou. Tá nielenže dopĺňa estetický vzhľad, ale zvyšuje čistiaci efekt.',
            text2: '17mm na bežné použitie (administratívne budovy, banky, hotely...), 22mm a 27mm na extrémnu záťaž (obchodné domy, centrá...hlavne tam, kde je predpoklad, že po rohoži budú prechádzať nákupné vozíky.)',
            text3: '9kg/m², 15kg/m², 17kg/m² (hmotnosť bez povrchov)',
            text4: '1,8mm a 2,6mm',
            text5: 'podľa priania zákazníka',
            text6: 'čistenie je potrebné aspoň raz týždenne, záleží podľa množstva znečistenia a pohybu ľudí. Povrch rohože vyčistíme hĺbkovým vysávačom, alebo vyzametáme a nečistoty pod rohožou jednoducho tiež vymetieme alebo vysajem vysávačom.  Dôležité pri textilnej rohoži je, aby sa používal na čistenie nízkootáčkový hĺbkový vysávač. V opačnom prípade môže dôjsť k znehodnoteniu textilného povrchu. Na spodnú časť rohože dávame tlmiacu gumu, ktorá tlmí nárazy a vyrovnáva drobné nerovnosti lôžka. Pri tomto type rohože je možné pri poškodení, alebo opotrebení vymeniť povrch.',
            text7: 'do všetkých exkluzívnych a moderných budov, rodinných domov, hotelov, bánk, nákupných centier... ',
            text8: 'najvhodnejší spôsob je vložiť do zapusteného AL rámu, ak nie je už možnosť zasahovať do podlahy, rohož môže byť položená v nábehovom AL ráme. Dôležitý je rovný podklad.',
            subitems: [
                {
                    id: 0, image: 'alumat/alumat_g.jpg', image_cut: 'alumat/alumat_g_rez.png', title: 'Alumat G', note: 's vložkou z gumovej pilky',
                    text1: 'hliníkové profily  spojené nerezovým lankom',
                    text2: 'gumová pilka',
                    text3: ' 22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa  objednávky',
                },
                {
                    id: 1, image: 'alumat/alumat_gy.jpg', image_cut: 'alumat/alumat_gy_rez.png', title: 'Alumat G/Y', note: 's vložkou z gumovej pilky s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 2, image: 'alumat/alumat_gk.jpg', image_cut: 'alumat/alumat_gk_rez.png', title: 'Alumat G/K', note: 's vložkou z gumovej pilky a kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'gumová pilka + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 3, image: 'alumat/alumat_gky.jpg', image_cut: 'alumat/alumat_gky_rez.png', title: 'Alumat G/K/Y', note: 's vložkou z gumovej pilky a kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 4, image: 'alumat/alumat_gw.jpg', image_cut: 'alumat/alumat_gw_rez.png', title: 'Alumat G / kefka jednoriadková', note: 's vložkou z gumovej pilky s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text2: 'gumová pilka s jednoradovým kartáčom',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 5, image: 'alumat/alumat_k.jpg', image_cut: 'alumat/alumat_k_rez.jpg', title: 'Alumat K', note: 'kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 6, image: 'alumat/alumat_ky.jpg', image_cut: 'alumat/alumat_ky_rez.png', title: 'Alumat K/Y', note: 'kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                }
            ],
            // ********************************************************************************************************************************************************************************
            subitems2: [
                {
                    id: 0, image: 'alumat/alumat_t.jpg', image_cut: 'alumat/alumat_t_rez.png', title: 'Alumat T', note: 's vložkou z polypropylénového vlákna',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: '100% ostrý polypropylén',
                    text3: ' 22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, šedý, béžový, melír',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 1, image: 'alumat/alumat_ty.jpg', image_cut: 'alumat/alumat_ty_rez.png', title: 'Alumat T/Y', note: 's vložkou z polypropylénového vlákna s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: '100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, šedý, béžový  melír',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 2, image: 'alumat/alumat_tk.jpg', image_cut: 'alumat/alumat_tk_rez.png', title: 'Alumat T/K', note: 's vložkou z polypropylénového vlákna a kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: '100% ostrý polypropylén + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'textil - antracit, šedý, béžový melír, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 3, image: 'alumat/alumat_tky.jpg', image_cut: 'alumat/alumat_tky_rez.png', title: 'Alumat T/K/Y', note: 's vložkou z polypropylénového vlákna a kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: '100% ostrý polypropylén + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'textil - antracit, šedý, béžový melír, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 4, image: 'alumat/alumat_gt.jpg', image_cut: 'alumat/alumat_gt_rez.png', title: 'Alumat G/T', note: 's vložkou z gumovej pilky a polypropylénového vlákna',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'gumová pilka + 100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5mm - 10mm',
                    text6: 'textil - antracit, šedý, béžový melír, gumová pilka - čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 5, image: 'alumat/alumat_gty.jpg', image_cut: 'alumat/alumat_gty_rez.png', title: 'Alumat G/T/Y', note: 's vložkou z gumovej pilky a polypropylénového vlákna s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka + 100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'textil - antracit, šedý, béžový melír, gumová pilka - čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 6, image: 'alumat/alumat_tw.jpg', image_cut: 'alumat/alumat_tw_rez.png', title: 'Alumat T / kefka jednoriadková', note: 's vložkou z polypropylénového vlákna s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text2: '100% polypropylén, jednoradový kartáč',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'antracit, šedá, béžová',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                }
            ]
        },
        {
            id: 1, enabled: false, type: 0, image: 'step_mat.jpg', title: 'Step mat', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 2, enabled: true, type: 0, image: 'octo_mat.jpg', title: 'Octo mat', description: '',
            text1: 'vchodová vonkajšia kaučuková rohož. Vrchná aj spodná strana má protišmkový povrch. Má drenážnu úpravu, čo umožňuje jednochuchý odvod vody a nečistôt spod rohožky. Do tejto rohože je možné dokúpiť farebné kartáčiky, ktoré môžu slúžiť na vytvorenie loga a zároveň zvyšujú čistenie. Vyhovuje protišmykovej norme En 13552 kategórie R10.',
            text2: '18mm a 23mm',
            text3: '11kg/m², 17kg/ks a 14kg/m², 20kg/ks',
            text4: '',
            text5: 'modul 150x100, ale aj na mieru zákazníka',
            text6: 'rohož sa zroluje, vystrieka vodou a nečistoty pod ňou sa vyzametajú, príp. vysajú hĺbkovým vysávačom.',
            text7: 'ideálna pre veľmi zaťažené vlhké zablatené a zasnežené miesta s veľkou frekvenciou osôb. Môžu po nej prechádzať nákupné vozíky aj autá.',
            text8: 'zapustená do podlahy v AL ráme, alebo volne položená na podlahu v nábehovom AL ráme.',
            subitems: [],
            subitems2: []
        },
        {
            id: 3, enabled: true, type: 0, image: 'octo_flex.jpg', title: 'Octo Flex', description: '',
            text1: 'vonkajšia vchodová rohož z kaučuku. Vrchná aj spodná strana má protišmykový povrch. Má drenážnu úpravu, čo umožňuje jednoduchý odvoch vody a nečistôt spod rohožky. Vyhovuje protišmyskovej norme EN 13552 kategórie R10',
            text2: '14mm',
            text3: '8kg/m², 12kg/ks',
            text4: '',
            text5: 'modul 150x100, alebo na mieru',
            text6: 'rohož sa zroluje, vystrieka vodou a nečistoty pod ňou sa vyzametajú',
            text7: 'vhodná na záťaž, zablatené a zasnežené miesta s veľkou frekvenciou osôb. Môžu po nej prechádzať nákupné vozíky.',
            text8: 'v zapustenom AL ráme.',
            subitems: [],
            subitems2: []
        },
        {
            id: 4, enabled: true, type: 1, image: 'arcos1.jpg', title: 'Arcos', description: '',
            text1: '100% polypropylén na gumovom podklade, povrch tvorí štvorriadkový textil, vlas je  v tvare slučiek Je určená do interiérov, kde sa predpokladá väčší pohyb ľudí.',
            text2: '17mm',
            text3: '4,5kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'rohož treba pravidelne vysávať, neublíži jej ani vlhké strojové čistenie',
            text7: 'do interiéru bánk, hotelov, obchodných a rodinných domov...',
            text8: 'rohož môže byť položená na dlažbe, olemovaná gumovou lištou, alebo osadená do podlahy v zapustenom AL ráme',
            subitems: [],
            subitems2: []
        },
        {
            id: 5, enabled: true, type: 1, image: 'arrow_trax.jpg', title: 'Arrox trax', description: '',
            text1: 'rohož s atraktívnym ihlovaným vzorom v tvare rybej kosti s výbornými čistiacimi vlastnosťami. Je určená pre interiéry s namáhavými prevádzkovými podmienkami.Tieto rohože pomáhajú predchádzať riziku pošmyknutia sa a zachytávajú množstvo nečistôt vo forme prachu a hliny vnášaných do budovy. Rohož má 1200g antistatického polypropylénového vlákna na m2',
            text2: '10mm',
            text3: '3,9kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'pravidelne vysávať',
            text7: 'výborná čistiaca rohož, vhodná ako druhá aj tretia zóna do interiérov bánk, hotelov, obch. centier, administr. budov...',
            text8: 'rohož môže byť volne položená na podlahu s gumeným olemovaním, alebo zapustená do AL rámu.',
            subitems: [],
            subitems2: []
        },
        {
            id: 6, enabled: true, type: 3, image: 'trava.jpg', title: 'Umelá tráva', description: 'Rohož do interiéru aj exteriéru',
            text1: '100% polypropylén, zospodu perforovaná pre lepší odtok vody. Táto rohožmôže byť umiestnená na terasách, pri bazénoch, na balkónoch... v zime i v lete.',
            text2: '5mm',
            text3: '0,80kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'nečistoty sa môžu vysať vysávačom, vyzametať. V zime sa rohož nemôže rolovať, pretože sa môže polámať',
            text7: 'všade, kde je potrebné dosiahnuť dizajn krásneho trávniku, na terasy hotelov,reštaurácií, balkóny...',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 7, enabled: true, type: 3, image: 'hrana.jpg', title: 'Schodová hrana', description: '',
            text1: 'základ tvorí hliníkový profil, do ktorého sa nasúva guma v tvare pilky. Profil môže byť umiestnený na všetkých druhoch schodov, môže sa prilepiť chemoprénom, alebo priskrutkovať.',
            text2: '(výška / šírka) 6mm/45mm',
            text3: '0,325kg/bm (bez povrchu)',
            text4: '',
            text5: '',
            text6: 'podľa želania, maximálna dĺžka 6m',
            text7: 'na všetky typy schodov',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 8, enabled: false, type: 3, image: 'clean_step.jpg', title: 'Fir step/clean step', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 9, enabled: true, type: 3, image: 'soft_step.jpg', title: 'Soft step', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 10, enabled: true, type: 3, image: 'sanitop1.jpg', title: 'Sanitop', description: '',
            text1: 'protišmykové a protiúnavové dierované rohože do interiéru',
            text2: '12.7 mm',
            text3: '8.06 kg/m²',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: 'prírodná čierna guma',
            subitems: [],
            subitems2: []
        },
    ]
}


// **********************************************************************
// EN - LANGUAGE
// **********************************************************************
export const en = {
    lang_id: 1,
    title: 'Aluma',
    description: 'Manufacture, sale and installation of mats and cleaning zones',
    home: 'Home',
    years_business: 'On the market for 16 years',
    banner_label: 'Mats and cleaning zones',

    required_data: '* Required data',

    today: 'Today',

    name: 'Name',
    surname: 'Surname',
    name_surname: 'Name and surname',
    street: 'Street',
    street_number: 'number',
    psc: 'PSC',
    town: 'Town',
    email: 'E-mail',
    email_error: 'Incorect e-mail format',
    mobil: 'Mobil',
    logo: 'Logo',
    ico: 'ICO',
    dic: 'DIC',
    ic_dph: 'IC-DPH',
    DPH: 'DPH',
    error: 'Error',

    continue: 'Continue',
    back: 'Back',
    close: 'Close',

    required: 'Required',
    number: 'Number',
    active: 'Active',
    label: 'Label',
    db_error: 'An error occurred while writing. Please try again',

    new: 'New',
    add: 'Add',
    ok: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    save_changes: 'Save changes',
    edit: 'Edit',
    search: 'Search',
    send: 'Send',
    saving: '...',
    subscribe: 'Subcribe',

    call_us: 'Call',

    gdpr: 'Privacy',
    cookies_rules: 'Cookies policy',
    sell_docs: 'General terms and conditions',

    about_us: 'About',
    products: 'Products',
    products_text: 'Manufacture and sale of mats',
    gallery: 'Gallery',
    gallery_text: 'Photos gallery',
    reference: 'Reference',
    reference_text: 'Our clients',
    contact: 'Contact',
    contact_text: 'Contact us',
    contact_data: 'Contact',

    more_info: 'More info',
    mat_enter: 'Entrance mats',
    mat_enter_text: 'We produce entrance mats to reduce pollution and humidity of the building. Entrance mats help protect the value of the building. Floor coverings will be protected from dirt and rising humidity.',
    our_products: 'Our products',
    mat_catalogue_text: 'Aluma mats ensure the cleanliness of your building',
    mat_catalogue: 'Catalog of mats Aluma',
    mat_int: 'Interior mats',
    mat_ext: 'Exterior mats',
    mat_ind: 'Industrial mats',
    our_clients: 'The biggest customers',
    banner_text: 'The mats are the first contact at the entrance to the building. Captures up to 90% of impurities.',
    message_question: 'Do you want to ask something? Contact us.',
    message_text: 'Message text',
    contact_form: 'Contact form',
    about_us_text1: 'Our company has been on the market since 2006. Since 2010, the legal form of the company has changed to ALUMA SK, s.r.o. We manufacture and install mats for cleaning zones.',
    about_us_text2: "Over the years, the company has undergone various changes, we have expanded not only the range, but also production facilities. We have sufficient material stocks, so we are fast, prompt and we try to meet all customer requirements. After all, today's times demand it.",
    about_us_text3: 'Since the beginning of our establishment, we have been the only company that we service our mats. Our advantage is that we produce according to the requirements and tailor-made of the customer. We cooperate with construction companies, designers and also with the end customer.',
    about_us_text4: 'We focus mainly on the supply of mats for shopping centers, office buildings, banks, hotels and houses. You will find our products not only on the Slovak market, but also in the Czech Republic and Hungary.',

    message_send_od: 'Message was succesfully sent',

    mat_data_1: 'Technical description:',
    mat_data_2: 'Height:',
    mat_data_3: 'Weight:',
    mat_data_4: 'Thickness:',
    mat_data_5: 'Dimensions:',
    mat_data_6: 'Maintenance:',
    mat_data_7: 'The use:',
    mat_data_8: 'Design:',

    sub_mat_1: 'Construction',
    sub_mat_2: 'Handle',
    sub_mat_3: 'Mat height',
    sub_mat_4: 'Frame height',
    sub_mat_5: 'Space',
    sub_mat_6: 'Handle color',
    sub_mat_7: 'Damping tape',
    sub_mat_8: 'Size',

    coworking: 'Cooperation with companies',
    alumat_ready: 'Construction preparation for installing embedded mats Alumat',

    mats: [
        {
            id: 0, enabled: true, type: 0, image: 'alumat_1.jpg', title: 'Alumat', description: 'Exkluzivita a jednoduchosť v jednom',
            text1: 'rohož z hliníkových profilov, spojená antikorovým lankom a gumičkami, ktoré tvoria medzery medzi profilmi a tým umožňujú prepadávanie nečistoty. Táto medzera môže byť 5mm, 8mm a 10mm. Povrch tvorí textil, guma (pilka), alebo protišmyková kocka, ktoré sa môžu striedať s hliníkovou škrabkou. Tá nielenže dopĺňa estetický vzhľad, ale zvyšuje čistiaci efekt.',
            text2: '17mm na bežné použitie (administratívne budovy, banky, hotely...), 22mm a 27mm na extrémnu záťaž (obchodné domy, centrá...hlavne tam, kde je predpoklad, že po rohoži budú prechádzať nákupné vozíky.)',
            text3: '9kg/m², 15kg/m², 17kg/m² (hmotnosť bez povrchov)',
            text4: '1,8mm a 2,6mm',
            text5: 'podľa priania zákazníka',
            text6: 'čistenie je potrebné aspoň raz týždenne, záleží podľa množstva znečistenia a pohybu ľudí. Povrch rohože vyčistíme hĺbkovým vysávačom, alebo vyzametáme a nečistoty pod rohožou jednoducho tiež vymetieme alebo vysajem vysávačom.  Dôležité pri textilnej rohoži je, aby sa používal na čistenie nízkootáčkový hĺbkový vysávač. V opačnom prípade môže dôjsť k znehodnoteniu textilného povrchu. Na spodnú časť rohože dávame tlmiacu gumu, ktorá tlmí nárazy a vyrovnáva drobné nerovnosti lôžka. Pri tomto type rohože je možné pri poškodení, alebo opotrebení vymeniť povrch.',
            text7: 'do všetkých exkluzívnych a moderných budov, rodinných domov, hotelov, bánk, nákupných centier... ',
            text8: 'najvhodnejší spôsob je vložiť do zapusteného AL rámu, ak nie je už možnosť zasahovať do podlahy, rohož môže byť položená v nábehovom AL ráme. Dôležitý je rovný podklad.',
            subitems: [
                {
                    id: 0, image: 'alumat/alumat_g.jpg', image_cut: 'alumat/alumat_g_rez.png', title: 'Alumat G', note: 's vložkou z gumovej pilky',
                    text1: 'hliníkové profily  spojené nerezovým lankom',
                    text2: 'gumová pilka',
                    text3: ' 22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa  objednávky',
                },
                {
                    id: 1, image: 'alumat/alumat_gy.jpg', image_cut: 'alumat/alumat_gy_rez.png', title: 'Alumat G/Y', note: 's vložkou z gumovej pilky s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 2, image: 'alumat/alumat_gk.jpg', image_cut: 'alumat/alumat_gk_rez.png', title: 'Alumat G/K', note: 's vložkou z gumovej pilky a kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'gumová pilka + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 3, image: 'alumat/alumat_gky.jpg', image_cut: 'alumat/alumat_gky_rez.png', title: 'Alumat G/K/Y', note: 's vložkou z gumovej pilky a kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 4, image: 'alumat/alumat_gw.jpg', image_cut: 'alumat/alumat_gw_rez.png', title: 'Alumat G / kefka jednoriadková', note: 's vložkou z gumovej pilky s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text2: 'gumová pilka s jednoradovým kartáčom',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'guma - čierna, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 5, image: 'alumat/alumat_k.jpg', image_cut: 'alumat/alumat_k_rez.jpg', title: 'Alumat K', note: 'kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 6, image: 'alumat/alumat_ky.jpg', image_cut: 'alumat/alumat_ky_rez.png', title: 'Alumat K/Y', note: 'kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                }
            ],
            // ********************************************************************************************************************************************************************************
            subitems2: [
                {
                    id: 0, image: 'alumat/alumat_t.jpg', image_cut: 'alumat/alumat_t_rez.png', title: 'Alumat T', note: 's vložkou z polypropylénového vlákna',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: '100% ostrý polypropylén',
                    text3: ' 22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, šedý, béžový, melír',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 1, image: 'alumat/alumat_ty.jpg', image_cut: 'alumat/alumat_ty_rez.png', title: 'Alumat T/Y', note: 's vložkou z polypropylénového vlákna s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: '100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, šedý, béžový  melír',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 2, image: 'alumat/alumat_tk.jpg', image_cut: 'alumat/alumat_tk_rez.png', title: 'Alumat T/K', note: 's vložkou z polypropylénového vlákna a kartáčová násada',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: '100% ostrý polypropylén + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'textil - antracit, šedý, béžový melír, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 3, image: 'alumat/alumat_tky.jpg', image_cut: 'alumat/alumat_tky_rez.png', title: 'Alumat T/K/Y', note: 's vložkou z polypropylénového vlákna a kartáčová násada s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: '100% ostrý polypropylén + kartáčová násada',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'textil - antracit, šedý, béžový melír, kartáč - podklad čierny, štetiny šedé',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 4, image: 'alumat/alumat_gt.jpg', image_cut: 'alumat/alumat_gt_rez.png', title: 'Alumat G/T', note: 's vložkou z gumovej pilky a polypropylénového vlákna',
                    text1: 'hliníkové profily spojené nerezovým lankom',
                    text2: 'gumová pilka + 100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5mm - 10mm',
                    text6: 'textil - antracit, šedý, béžový melír, gumová pilka - čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 5, image: 'alumat/alumat_gty.jpg', image_cut: 'alumat/alumat_gty_rez.png', title: 'Alumat G/T/Y', note: 's vložkou z gumovej pilky a polypropylénového vlákna s pridaním hliníkovej škrabky',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky',
                    text2: 'gumová pilka + 100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'textil - antracit, šedý, béžový melír, gumová pilka - čierna',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 6, image: 'alumat/alumat_tw.jpg', image_cut: 'alumat/alumat_tw_rez.png', title: 'Alumat T / kefka jednoriadková', note: 's vložkou z polypropylénového vlákna s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkového medziprofilu s jednoradovým kartáčom',
                    text2: '100% polypropylén, jednoradový kartáč',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'antracit, šedá, béžová',
                    text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                }
            ]
        },
        {
            id: 1, enabled: false, type: 0, image: 'step_mat.jpg', title: 'Step mat', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 2, enabled: true, type: 0, image: 'octo_mat.jpg', title: 'Octo mat', description: '',
            text1: 'vchodová vonkajšia kaučuková rohož. Vrchná aj spodná strana má protišmkový povrch. Má drenážnu úpravu, čo umožňuje jednochuchý odvod vody a nečistôt spod rohožky. Do tejto rohože je možné dokúpiť farebné kartáčiky, ktoré môžu slúžiť na vytvorenie loga a zároveň zvyšujú čistenie. Vyhovuje protišmykovej norme En 13552 kategórie R10.',
            text2: '18mm a 23mm',
            text3: '11kg/m², 17kg/ks a 14kg/m², 20kg/ks',
            text4: '',
            text5: 'modul 150x100, ale aj na mieru zákazníka',
            text6: 'rohož sa zroluje, vystrieka vodou a nečistoty pod ňou sa vyzametajú, príp. vysajú hĺbkovým vysávačom.',
            text7: 'ideálna pre veľmi zaťažené vlhké zablatené a zasnežené miesta s veľkou frekvenciou osôb. Môžu po nej prechádzať nákupné vozíky aj autá.',
            text8: 'zapustená do podlahy v AL ráme, alebo volne položená na podlahu v nábehovom AL ráme.',
            subitems: [],
            subitems2: []
        },
        {
            id: 3, enabled: true, type: 0, image: 'octo_flex.jpg', title: 'Octo Flex', description: '',
            text1: 'vonkajšia vchodová rohož z kaučuku. Vrchná aj spodná strana má protišmykový povrch. Má drenážnu úpravu, čo umožňuje jednoduchý odvoch vody a nečistôt spod rohožky. Vyhovuje protišmyskovej norme EN 13552 kategórie R10',
            text2: '14mm',
            text3: '8kg/m², 12kg/ks',
            text4: '',
            text5: 'modul 150x100, alebo na mieru',
            text6: 'rohož sa zroluje, vystrieka vodou a nečistoty pod ňou sa vyzametajú',
            text7: 'vhodná na záťaž, zablatené a zasnežené miesta s veľkou frekvenciou osôb. Môžu po nej prechádzať nákupné vozíky.',
            text8: 'v zapustenom AL ráme.',
            subitems: [],
            subitems2: []
        },
        {
            id: 4, enabled: true, type: 1, image: 'arcos1.jpg', title: 'Arcos', description: '',
            text1: '100% polypropylén na gumovom podklade, povrch tvorí štvorriadkový textil, vlas je  v tvare slučiek Je určená do interiérov, kde sa predpokladá väčší pohyb ľudí.',
            text2: '17mm',
            text3: '4,5kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'rohož treba pravidelne vysávať, neublíži jej ani vlhké strojové čistenie',
            text7: 'do interiéru bánk, hotelov, obchodných a rodinných domov...',
            text8: 'rohož môže byť položená na dlažbe, olemovaná gumovou lištou, alebo osadená do podlahy v zapustenom AL ráme',
            subitems: [],
            subitems2: []
        },
        {
            id: 5, enabled: true, type: 1, image: 'arrow_trax.jpg', title: 'Arrox trax', description: '',
            text1: 'rohož s atraktívnym ihlovaným vzorom v tvare rybej kosti s výbornými čistiacimi vlastnosťami. Je určená pre interiéry s namáhavými prevádzkovými podmienkami.Tieto rohože pomáhajú predchádzať riziku pošmyknutia sa a zachytávajú množstvo nečistôt vo forme prachu a hliny vnášaných do budovy. Rohož má 1200g antistatického polypropylénového vlákna na m2',
            text2: '10mm',
            text3: '3,9kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'pravidelne vysávať',
            text7: 'výborná čistiaca rohož, vhodná ako druhá aj tretia zóna do interiérov bánk, hotelov, obch. centier, administr. budov...',
            text8: 'rohož môže byť volne položená na podlahu s gumeným olemovaním, alebo zapustená do AL rámu.',
            subitems: [],
            subitems2: []
        },
        {
            id: 6, enabled: true, type: 3, image: 'trava.jpg', title: 'Umelá tráva', description: 'Rohož do interiéru aj exteriéru',
            text1: '100% polypropylén, zospodu perforovaná pre lepší odtok vody. Táto rohožmôže byť umiestnená na terasách, pri bazénoch, na balkónoch... v zime i v lete.',
            text2: '5mm',
            text3: '0,80kg/m²',
            text4: '',
            text5: 'podľa priania zákazníka',
            text6: 'nečistoty sa môžu vysať vysávačom, vyzametať. V zime sa rohož nemôže rolovať, pretože sa môže polámať',
            text7: 'všade, kde je potrebné dosiahnuť dizajn krásneho trávniku, na terasy hotelov,reštaurácií, balkóny...',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 7, enabled: true, type: 3, image: 'hrana.jpg', title: 'Schodová hrana', description: '',
            text1: 'základ tvorí hliníkový profil, do ktorého sa nasúva guma v tvare pilky. Profil môže byť umiestnený na všetkých druhoch schodov, môže sa prilepiť chemoprénom, alebo priskrutkovať.',
            text2: '(výška / šírka) 6mm/45mm',
            text3: '0,325kg/bm (bez povrchu)',
            text4: '',
            text5: '',
            text6: 'podľa želania, maximálna dĺžka 6m',
            text7: 'na všetky typy schodov',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 8, enabled: false, type: 3, image: 'clean_step.jpg', title: 'Fir step/clean step', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 9, enabled: true, type: 3, image: 'soft_step.jpg', title: 'Soft step', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 10, enabled: true, type: 3, image: 'sanitop1.jpg', title: 'Sanitop', description: '',
            text1: 'protišmykové a protiúnavové dierované rohože do interiéru',
            text2: '12.7 mm',
            text3: '8.06 kg/m²',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: 'prírodná čierna guma',
            subitems: [],
            subitems2: []
        },
    ]
}


// **********************************************************************
// HU - LANGUAGE
// **********************************************************************
export const hu = {
    lang_id: 2,
    title: 'Aluma',
    description: 'Szőnyegek és tisztítózónák gyártása, értékesítése, összeszerelése',
    banner_label: 'Szőnyegek és tisztítózónák',

    home: 'itthon',
    years_business: 'Már 16 éve a piacon',
    required_data: 'A *-gal jelölt adatok megadása kötelező',

    months: ['január”, „február”, „március”, „április”, „május”, „június”, „július”, „augusztus”, „szeptember”, „október”', 'November, december'],
    months_short: ['jan.', 'febr.', 'márc.', 'ápr.', 'május', 'jún.', 'júl.', 'aug.', 'szept.', 'okt.', 'nov. .', 'dec.'],
    days: ['Vasárnap, Hétfő, Kedd ,Szerda ,Csütörtök, Péntek, Szombat'],
    days_short: ['V.', 'H.', 'K.', 'Sz.', 'Cs.', 'P.', 'Sz.'],
    today: 'Ma',

    name: 'Név',
    surname: 'Vezetéknév',
    name_surname: 'Vezetéknév, keresztnév',
    street: 'utca',
    street_number: 'szám',
    psc: 'Irányítószám',
    town: 'város',
    email: 'Email',
    email_error: 'Helytelen e-mail-cím formátum',
    mobil: 'Mobil',
    phone: 'Telefonszám',
    state: 'Ország',
    logo: 'Logó',
    ico: 'cégjegyzékszám ',
    dic: 'adóazonosító jel ',
    ic_dph: 'adószám',
    DPH: 'ÁFA ',
    error: 'Hiba',

    continue: 'Folytatni',
    back: 'Vissza',
    close: 'Bezárás',

    required: 'kötelező információ',
    number: 'Szám',
    active: 'Aktív',
    label: 'Cím',
    db_error: 'Hiba történt írás közben. Próbálja újra a kérést',

    new: 'Új',
    add: 'Hozzáadás',
    ok: 'RENDBEN',
    cancel: 'Megszünteti',
    save: 'Megment',
    save_changes: 'Változtatások mentése',
    edit: 'Megigazítani',
    search: 'Keresés',
    send: 'Küldés',
    saving: 'megtakarítás',
    subscribe: 'előfizető',

    call_us: 'Hívás',

    gdpr: 'Személyes adatok védelme',
    cookies_rules: 'A cookie-k hasznalátára vonatkozó szabályzat ',
    sell_docs: 'Általános szerződési feltételek',

    about_us: 'Rólunk',
    products: 'Termékek',
    products_text: 'Szőnyegek gyártása és értékesítése',
    gallery: 'Képgaléria',
    gallery_text: 'Képgaléria',
    reference: 'Hivatkozások',
    reference_text: 'Az ügyfeleink',
    contact: 'kapcsolatba lépni',
    contact_text: 'Lépjen kapcsolatban velünk',
    contact_data: 'Elérhetőség',
    more_info: 'Több információ',

    mat_enter: 'Bejárati szőnyegek',
    mat_enter_text: 'Bejárati szőnyegeket gyártunk, hogy csökkentsük a szennyezést és a nedvességet az épületben.A bejárati szőnyegek hozzájárulnak az ingatlan értékének védelméhez. A padlóburkolatok védve lesznek a szennyeződéstől és a megnövekedett páratartalomtól.',
    our_products: 'Termékeink',
    mat_catalogue: 'Aluma szőnyegek katalógusa',
    mat_catalogue_text: 'Az Aluma szőnyegek gondoskodnak az épület tisztaságáról',
    mat_int: 'Belső szőnyegek',
    mat_ext: 'Külső szőnyegek',
    mat_ind: 'Ipari szőnyegek',
    our_clients: 'A legnagyobb vásárlók',
    banner_text: 'A szőnyegek jelentik az első érintkezést az épületbe való belépéskor. A szennyeződés 90%-át felfogják.',
    message_question: 'Akarsz kérdezni valamit? Lépjen kapcsolatba velünk.',
    message_text: 'Üzenet szövege',
    contact_form: 'Kapcsolatfelvételi űrlap',
    about_us_text1: 'Cégünk 2006 óta van a piacon. 2010 óta a társaság jogi formája ALUMA SK, s.r.o. Tisztítózónák szőnyegeinek gyártásával és összeszerelésével foglalkozunk.', about_us_text2: 'Az évek során a cég változatos változásokon ment keresztül, nemcsak a termékpalettát, hanem a gyártóhelyiségeket is bővítettük. Elegendő anyagkészlettel rendelkezünk, ezért gyorsak, készségesek vagyunk és igyekszünk minden vásárlói igényt kielégíteni. Hiszen a mai idők megkívánják.',
    about_us_text3: 'Megalakulásunk kezdete óta egyedüliként szervizeljük szőnyegeinket. Előnyünk, hogy az igények szerint és a megrendelői méretek szerint gyártunk. Együttműködünk építőipari cégekkel, tervezőkkel és a végső megrendelővel is.',
    about_us_text4: 'Elsősorban bevásárlóközpontok, adminisztratív épületek, bankok, szállodák és családi házak szőnyegellátására koncentrálunk. Termékeinket nemcsak a szlovák piacon, hanem a Cseh Köztársaságban és Magyarországon is megtalálja.',

    message_send_od: 'Az üzenetet sikeresen elküldtük',

    mat_data_1: 'Műszaki leíras',
    mat_data_2: 'Magasság: ',
    mat_data_3: 'Súly: ',
    mat_data_4: 'Vastagság: ',
    mat_data_5: 'Méretek: ',
    mat_data_6: 'Karbantartás: ',
    mat_data_7: 'Felhasználás: ',
    mat_data_8: 'Végrehajtás: ',

    sub_mat_1: 'Szerkezet',
    sub_mat_2: 'Fogantyú',
    sub_mat_3: 'Az alátét magassága',
    sub_mat_4: 'Keretmagasság',
    sub_mat_5: 'Névtér',
    sub_mat_6: 'Csatolmány színe',
    sub_mat_7: 'Csillapító szalag',
    sub_mat_8: 'Dimenzió:',

    coworking: 'Együttműködés a vállalatokkal',
    alumat_ready: 'Építési előkészületek süllyesztett Alumat szőnyegek telepítéséhez',

    mats: [
        {
            id: 0, enabled: true, type: 0, image: 'alumat_1.jpg', title: 'Alumat', description: 'Exkluzivitás és egyszerűség egyben',
            text1: 'alumíniumprofilokból készült szőnyeg, rozsdamentes acélkötéllel és gumiszalagokkal összekötve, amelyek réseket hoznak létre a profilok között, és így lehetővé teszik a szennyeződés átesését.Ez a rés 5 mm, 8 mm és 10 mm lehet.A felület textíliákból, gumiból(fűrész) vagy csúszásgátló kockából áll, amely alumínium kaparóval váltogatható.Nemcsak az esztétikai megjelenést egészíti ki, hanem a tisztító hatást is fokozza.',
            text2: '17 mm közös használatra (irodaházak, bankok, szállodák...), 22 mm és 27 mm extrém terhekhez (áruházak, központok...különösen, ahol a bevásárlókocsik várhatóan áthaladnak a szőnyegen.)',
            text3: '9kg/m2, 15kg/m2, 17kg/m2 (súly felületek nélkül)',
            text4: '1,8mm a 2,6mm',
            text5: 'a megrendelő kívánsága szerint',
            text6: 'a tisztítás legalább hetente egyszer szükséges, a szennyezés mértékétől és az emberek mozgásától függően.Tisztítsd meg az alátét felületét mély porszívóval, vagy söpörd le és egyszerűen söpörd a szennyeződést az alátét alá egy porszívóval.A textilszőnyegekben fontos, hogy alacsony sebességű mélytisztítót használjanak a tisztításhoz.Ellenkező esetben a textil felülete megromolhat.A szőnyeg alsó részére ütéscsillapító gumit teszünk, amely csillapítja az ütéseket és kiegyenlíti az ágy kisebb egyenetlenségeit.Ezzel a típusú szőnyeggel lehetséges a felület cseréje sérülés vagy kopás esetén.',
            text7: 'minden exkluzív és modern épületbe, családi házba, szállodába, bankba, bevásárlóközpontba...',
            text8: 'a legalkalmasabb, ha süllyesztett AL keretbe tesszük, ha már nem lehet a padlót zavarni, akkor a szőnyeget felfutó AL keretbe lehet fektetni. A lapos alap fontos.',
            subitems: [{
                id: 0, image: 'alumat/alumat_g.jpg', image_cut: 'alumat/alumat_g_rez.png', title: 'Alumat G', note: 'gumi fűrészbetéttel',
                text1: ' rozsdamentes acélhuzallal összekötött alumínium profilok,', text2: 'gumi fűrész',
                text3: ' 22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'fekete',
                text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            },
            {
                id: 1, image: 'alumat/alumat_gy.jpg', image_cut: 'alumat/alumat_gy_rez.png', title: 'Alumat G/Y', note: 'gumifűrészbetéttel -val pridaním hliníkovej škrabky', text1: 'rozsdamentes acél kábellel összekötött alumínium profilok alumínium kaparóval',
                text2: 'gumifűrész',
                text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'fekete',
                text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }, {
                id: 2, image: 'alumat/alumat_gk.jpg', image_cut: 'alumat/alumat_gk_rez.png', title: 'Alumat G/K', note: 'gumi fűrészbetéttel és kefe nyéllel',
                text1: 'alumínium profilok, amelyek rozsdamentes acél kábellel vannak összekötve',
                text2: 'gumi fűrészkefe nyél',
                text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'gumi - fekete, ecset - fekete alap, szürke sörték', text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }, {
                id: 3, image: 'alumat/alumat_gky.jpg', image_cut: 'alumat/alumat_gky_rez.png', title: 'Alumat G/K/Y', note: 'gumi fűrészbetéttel és kefenyéllel, alumínium kaparóval',
                text1: 'alumínium profilok, amelyek rozsdamentes acél kábellel vannakösszekötve, alumínium kaparóval',
                text2: 'gumi fűrészkefe nyél',
                text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'gumi - fekete, ecset - fekete alap, szürke sörték', text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }, {

                id: 4, image: 'alumat/alumat_gw.jpg', image_cut: 'alumat/alumat_gw_rez.png', title: 'Alumat G / egysoros ecset', note: 'gumi fűrészbetéttel, alumínium köztes profil hozzáadásával egysoros kefével',
                text1: 'rozsdamentes acél kábellel összekötött alumínium profilok, alumínium köztes profil hozzáadásával egysoros kefével',
                text2: 'gumifűrész egysoros kefével', text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'gumi - fekete, ecset - fekete alap, szürke sörték', text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }, {
                id: 5, image: 'alumat/alumat_k.jpg', image_cut: 'alumat/alumat_k_rez.jpg', title: 'Alumat K', note: 'kefe nyél',
                text1: 'rozsdamentes acél kábellel összekötött alumínium profilok', text2: 'kefe nyél',
                text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm - 10 mm',
                text6: 'alap fekete, sörték szürke',
                text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }, {
                id: 6, image: 'alumat/alumat_ky.jpg', image_cut: 'alumat/alumat_ky_rez.png', title: 'Alumat K/Y', note: 'kefe nyél alumínium kaparóval kiegészítve',
                text1: 'kefe nyél alumínium kaparóval kiegészítve', text2: 'kartáčová násada', text3: '22, 17 mm',
                text4: '25, 20 mm',
                text5: '5 mm',
                text6: 'alap fekete, sörték szürke',
                text7: '1 mm',
                text8: 'pontosan a megrendelésnek megfelelően szabva',
            }],
            // ************************************************************************************************************* *******************************************************************
            subitems2: [

                {
                    id: 0, image: 'alumat/alumat_t.jpg', image_cut: 'alumat/alumat_t_rez.png',
                    title: 'Alumat T', note: 'polipropilén szálbetéttel',
                    text1: 'rozsdamentes acél kábellel összekötött alumínium profilok',
                    text2: '100% éles polipropilén',
                    text3: ' 22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, szürke, bézs, kiemelés',
                    text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                }, {
                    id: 1, image: 'alumat/alumat_ty.jpg', image_cut: 'alumat/alumat_ty_rez.png', title: 'Alumat T/Y', note: 's vložkou z polypropylénového vlákna s pridaním hliníkovej škrabky', text1: 'hliníkové profily spojené nerezovým lankom s pridaním hliníkovej škrabky', text2: '100% ostrý polypropylén',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'antracit, šedý, béžový melír', text7: '1 mm',
                    text8: 'presne na mieru podľa objednávky',
                },
                {
                    id: 2, image: 'alumat/alumat_tk.jpg', image_cut: 'alumat/alumat_tk_rez.png',
                    title: 'Alumat T/K', note: 'polipropilén szál betéttel és kefe nyéllel', text1: 'rozsdamentes acélhuzallal összekötött alumínium profilok',
                    text2: '100% éles polipropilén kefe nyél', text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm',
                    text6: 'szövet - antracit, szürke, bézs kiemelések, ecset - fekete alap, szürke sörték',
                    text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                },
                {
                    id: 3, image: 'alumat/alumat_tky.jpg', image_cut: 'alumat/alumat_tky_rez.png',
                    title: 'Alumat T/K/Y', note: 'polipropilén szálbetéttel és kefenyéllel alumínium kaparóval',
                    text1: 'alumínium profilok, amelyek rozsdamentes acél kábellel vannak összekötve, alumínium kaparóval',
                    text2: '100 % éles polipropilén kefe nyél',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'szövet - antracit, szürke, bézs kiemelések, ecset - fekete alap, szürke sörték',
                    text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                },
                {
                    id: 4, image: 'alumat/alumat_gt.jpg', image_cut: 'alumat/alumat_gt_rez.png',
                    title: 'Alumat G/T', note: 'gumifűrészporból és polipropilén szálból készült betéttel', text1: 'rozsdamentes acél kábellel összekötött alumínium profilok',
                    text2: 'gumifűrész 100% éles polipropilén', text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5mm - 10mm',
                    text6: 'textil - antracit, szürke, bézs kiemelés, gumifűrész - fekete', text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                }, {
                    id: 5, image: 'alumat/alumat_gty.jpg', image_cut: 'alumat/alumat_gty_rez.png', title: 'Alumat G/T/Y', note: 'gumifűrészporból és polipropilén szálból készült betéttel, alumínium kaparóval',
                    text1: 'alumínium profilok, amelyek rozsdamentes acél kábellel vannak összekötve, alumínium kaparóval',
                    text2: 'gumifűrész 100% éles polipropilénből',
                    text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'textil - antracit, szürke, bézs kiemelés, gumifűrész - fekete', text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                }, {
                    id: 6, image: 'alumat/alumat_tw.jpg', image_cut: 'alumat/alumat_tw_rez.png', title: 'Alumat T / egysoros ecset', note: 'polipropilén szálból készült betéttel, alumínium köztes profil hozzáadásával egysoros kefével',
                    text1: 'rozsdamentes acél kábellel összekötött alumínium profilok, alumínium köztes profil hozzáadásával egysoros kefével',
                    text2: '100% polipropilén, egysoros kefe', text3: '22, 17 mm',
                    text4: '25, 20 mm',
                    text5: '5 mm - 10 mm',
                    text6: 'antracit, szürke, bézs',
                    text7: '1 mm',
                    text8: 'pontosan a megrendelésnek megfelelően szabva',
                }
            ]
        },
        {
            id: 1, enabled: false, type: 0, image: 'step_mat.jpg', title: 'Step mat', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        }, {
            id: 2, enabled: true, type: 0, image: 'octo_mat.jpg', title: 'Octo mat', description: '',
            text1: 'bejárati kültéri gumiszőnyeg. A felső és az alsó rész csúszásmentes felülettel rendelkezik. Vízelvezető kezeléssel rendelkezik, amely lehetővé teszi a víz és a szennyeződés egyirányú elvezetését a szőnyeg alól. Ehhez a szőnyeghez színes ecsetek vásárolhatók, amelyek segítségével logót készíthet, és egyben növelheti a tisztítást. Megfelel az En 13552 R10 kategóriájú csúszásgátló szabványnak.',
            text2: '18mm a 23mm',
            text3: '11kg/m2, 17kg/ks a 14kg/m2, 20kg/ks',
            text4: '',
            text5: 'modul 150x100, de testre szabott is',
            text6: 'a szőnyeget feltekerjük, vízzel lepermetezzük és az alatta lévő szennyeződéstlesöpörjük, ill.mélyporszívóval porszívóznak.',
            text7: 'ideális erősen terhelt vizes, sáros és havas helyekre, ahol gyakrantartózkodik az ember.Bevásárlókocsik és autók is átmehetnek rajta',
            text8: 'padlóba ágyazva AL keretben, vagy szabadon a padlóra helyezve bejáratott AL keretben.',
            subitems: [],
            subitems2: []
        },
        {
            id: 3, enabled: true, type: 0, image: 'octo_flex.jpg', title: 'Octo Flex', description: '',
            text1: 'gumiból készült külső bejárati szőnyeg. Mind a felső, mind az alsó oldal csúszásgátló felülettel rendelkezik. Vízelvezető kezeléssel rendelkezik, amely lehetővé teszi a víz és a szennyeződés könnyű leeresztését a szőnyeg alól. Megfelel az EN 13552 csúszásgátló szabvány R10 kategóriájának',
            text2: '14mm',
            text3: '8kg/m2, 12kg/ks',
            text4: '',
            text5: '150x100 modul, vagy testreszabott',
            text6: 'a szőnyeget feltekerik, vízzel lepermetezik, és az alatta lévő szennyeződést elsöpörik',
            text7: 'terhekre, sáros és havas helyekre alkalmas, ahol nagy az emberek gyakorisága.  A bevásárlókocsik áthaladhatnak rajta',
            text8: 'beágyazott AL keretben',
            subitems: [],
            subitems2: []
        },
        {
            id: 4, enabled: true, type: 1, image: 'arcos1.jpg', title: 'Arcos', description: '',
            text1: '100% polipropilén gumi alapon, felülete négysoros textil, a bolyhos hurka formájú.Belsőkbe való ahol nagyobb embermozgás várható.',
            text2: '17mm',
            text3: '4,5kg/m2',
            text4: '',
            text5: 'az ügyfél kívánsága szerint',
            text6: 'a szőnyeget rendszeresen fel kell porszívózni, még a nedves gépitisztítás sem árt neki',
            text7: 'bankok, szállodák, kereskedelmi és családiházak belső tereire...',
            text8: 'a szőnyeg a járdára fektethető, gumiszalaggal szegélyezhető, vagy süllyesztett AL keretben a padlóba szerelhető',
            subitems: [],
            subitems2: []
        },
        {
            id: 5, enabled: true, type: 1, image: 'arrow_trax.jpg', title: 'Arrox trax', description: '',
            text1: 'szőnyeg vonzó halszálkás tűmintával, kiváló tisztító tulajdonságokkal. Megerőltető üzemi körülményekkel rendelkező belső terekhez készült, ezek a szőnyegek segítenek megelőzni a csúszásveszélyt, és sok szennyeződést felfognak az épületbe bevitt por és agyag formájában. A szőnyeg négyzetméterenként 1200 g antisztatikus polipropilén szálat tartalmaz',
            text2: '10mm',
            text3: '3,9kg/m2',
            text4: '',
            text5: 'az ügyfél kívánsága szerint',
            text6: 'porszívózni rendszeresen',
            text7: 'kiváló tisztítószőnyeg, alkalmas második és harmadik zónaként bankok, szállodák, üzletek belső terébe.központok, admin.épületek...',
            text8: 'a szőnyeg szabadon fektethető a padlóra gumi szegéllyel, vagy beágyazható az AL keretbe.',
            subitems: [],
            subitems2: []
        }, {
            id: 6, enabled: true, type: 3, image: 'trava.jpg', title: 'Műfű', description: 'Beltéri és kültéri szőnyeg',
            text1: '100% polipropilén, alul perforált a jobb vízelvezetés érdekében. Ez a szőnyeg elhelyezhető teraszokon, úszómedencék mellett, erkélyeken... télen-nyáron.',
            Text2: '5mm',
            Text3: '0, 80kg / m2',
            text4: '',
            text5: 'az ügyfél kívánsága szerint',
            text6: 'szennyeződés porszívóval felszívható, seperhető. Télen a szőnyeg nem tekerhető fel, mert eltörhet',
            text7: 'bárhol, ahol gyönyörű pázsit kialakítást kell elérni, szállodák, éttermek teraszain, erkélyein...',
            text8: '',
            subitems: [],
            subitems2: []
        }, {
            id: 7, enabled: true, type: 3, image: 'hrana.jpg', title: 'LÉPCSŐ SZÉLE', description: '', text1: 'az alap egy alumínium profilból áll, amelybe a fűrész alakú gumit helyezik. A profil minden lépcsőtípusra felhelyezhető, kemoprénnel ragasztható vagy csavarozható.',
            text2: '(magasság/szélesség) 6mm/45mm', text3: '0,325kg/bm (felület nélkül)',
            text4: '',
            text5: '',
            text6: 'kívánság szerint, maximális hossz 6m', text7: 'minden típusú lépcsőhöz',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {

            id: 8, enabled: false, type: 3, image: 'clean_step.jpg', title: 'Első lépés/tiszta lépés', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        },
        {
            id: 9, enabled: true, type: 3, image: 'soft_step.jpg', title: 'Soft step', description: '',
            text1: '',
            text2: '',
            text3: '',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: '',
            subitems: [],
            subitems2: []
        }, {

            id: 10, enabled: true, type: 3, image: 'sanitop1.jpg', title: 'Sanitop', description: '',
            text1: 'csúszásgátló és kifáradásgátló perforált szőnyegek a belső térbe',
            text2: '12.7 mm',
            text3: '8.06 kg/m2',
            text4: '',
            text5: '',
            text6: '',
            text7: '',
            text8: 'természetes fekete gumi',
            subitems: [],
            subitems2: []
        },
    ]
}